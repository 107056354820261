export default {
  news_pagination_count: 'Количество новостей на странице "Новости"',
  news_home_count: 'Количество новостей на странице "Главная"',
  count_paginate_catalog: 'Количество объявлений на странице "Каталог"',
  count_product_subscription: 'Количество объявлений в подписке',
  logo: 'Логотип',
  date: 'Дата в подвале сайта',
  popup_agreement_check: 'Соглашения о предоставлении онлайн-сервисов (Ссылка)',
  popup_agreement: 'Соглашение об использовании сайта (Ссылка)',
  popup_agreement_check_name_ru: 'Соглашение о предоставлении онлайн-сервисов (Заголовок ru)',
  popup_agreement_check_name_ua: 'Соглашение о предоставлении онлайн-сервисов (Заголовок ua)',
  popup_agreement_name_ru: 'Соглашение об использовании сайта (Заголовок ru)',
  popup_agreement_name_ua: 'Соглашение об использовании сайта (Заголовок ua)',
}
