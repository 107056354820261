<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Настройка: Основные настройки</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Редактирование настроек</h5>
        </div>
      </div>
      <div class="card-body">
        <main-settings-table :list="list" @getList="getList" />
      </div>
    </div>
  </div>
</template>

<script>
import MainSettingsTable from '@/components/content/settings/MainSettingsTable'

export default {
  name: 'main_settings',

  components: {
    MainSettingsTable,
  },

  data() {
    return {
      list: [],
    }
  },

  created() {
    this.getList()
  },

  methods: {
    async getList() {
      try {
        this.list = (await this.$services.get('admin/settings')).data.data.values
      } catch (e) {
        console.log(e)
      }
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current
      this.getList()
    },
  },
}
</script>
