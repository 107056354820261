<template>
  <div>
    <a-table :columns="columns" :dataSource="list" :row-key="(record, index) => record.id + index">
      <a
        slot="id"
        slot-scope="text"
        href="javascript: void(0);"
        class="btn btn-sm btn-light"
      >
        {{text}}
      </a>
      <span slot="name" slot-scope="record"> {{ settings[record.key] }}</span>
      <span v-if="!record.key.includes('logo')" slot="value" slot-scope="record"> {{ record.value }}</span>
      <span v-else slot="value" slot-scope="record" class="logo">
        <img :src="record.value" alt="logo">
      </span>
      <span slot="action" slot-scope="record">
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$refs.editModal.open(record, settings[record.key])">
          <i class="fe fe-edit mr-2" />
          Изменить
        </a>
      </span>
    </a-table>
    <edit-main-settings-modal ref="editModal" @getList="$emit('getList')" />
  </div>
</template>

<script>
import settings from '@/entities/settings'
import EditMainSettingsModal from '@/components/content/mainSettings/EditMainSettingsModal'

export default {
  name: 'MainSettingsTable',

  components: {
    EditMainSettingsModal,
  },

  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  data() {
    return {
      settings,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название страницы',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Значение',
          scopedSlots: { customRender: 'value' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.logo {
  width: auto;
  height: 24px;
  display: block;
  img {
    height: 100%;
  }
}
</style>
